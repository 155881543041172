<template lang="pug">
	#Noticias
		.filterContainer
			p.title O que está buscando?
			.top
				input(v-model="search" placeholder="Notícia")
				FontAwesomeIcon(:icon="icons.faMagnifyingGlass").searchIcon
			.bottom
				p De:
				.searchField
					input(type="date" v-model="startDate")
				p Até:
				.searchField
					input(type="date" v-model="endDate")
			.hr
				hr
		Spinner(v-if="displayNews.length === 0 && search == ''" id="spinner")
		ul
			li(v-for="noticias, index in displayNews" v-if="index < displayedNews && displayNews.length > 0")
				router-link(:to="{name: 'Noticia', params: {slug: noticias.translations[translationIndex].slug}}")
					.img
						img(:src="noticias.translations[translationIndex].thumb").image
					.container
						.title
							h3 {{ noticias.translations[translationIndex].title }}
						.wrapper
							.bloco 
								p.data {{ formatDate(noticias.translations[translationIndex].publishedAt) }}
								p.conteudo {{ noticias.translations[translationIndex].description }}
								router-link(:to="{name: 'Noticia', params: {slug: noticias.translations[translationIndex].slug}}").squareButton LEIA MAIS
		button(@click="expandNews" v-if="displayNews.length > 0 && showButton").squareButton MAIS NOTÍCIAS
		h2(v-if="displayNews.length === 0 && search !== ''") Não foram encontrados resultados.
</template>

<script> 
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import Spinner from '@components/Spinner/Spinner'

export default {
	name: "noticias",
	props: {
		contents: {
			type: Object,
		}
	},
	components: {
		Spinner
	},
	data() {
		return {
			search: '',
			startDate: null,
			endDate: null,
			translationIndex: 0,
			showButton: true,
			paginatedNews: [],
			displayedNews: 4,
			step: 4,
			icons: {
				faMagnifyingGlass
			},
			allNews: [],
		}
	},
	methods: {
		searchOptions(option) {
			let formattedOption = option ? option.toLowerCase() : ''
			if (formattedOption.includes(this.search.toLowerCase()))
				return true
			return false
		},
		formatDate(date) {
			const objData = new Date(date + 'T03:00:00')
			let month = objData.getMonth()
			if (month < 9) {
				month = `0${month + 1}`
			}
			else {
				month += 1
			}
			let formatted = `${objData.getDate()}/${month}/${objData.getFullYear()}`
			return formatted
		},
		compareDate(date) {
			if (this.startDate !== null && this.startDate >= date) return false
			if (this.endDate !== null && this.endDate <= date) return false
			return true
		},
		expandNews() {
			this.displayedNews += this.step
			this.$store.dispatch('fetchNews', { skip: this.paginatedNews.length, take: this.step })
		},
	},
	computed: {
		displayNews() {
			if (!this.search && !this.startDate && !this.endDate) {
				this.showButton = this.news.length === this.step;
				this.paginatedNews.push(...this.news)
				return this.paginatedNews
			}
			this.showButton = false
			this.allNews = this.fullNews.filter(news => {
				if ((this.searchOptions(news.translations[this.translationIndex].title) || this.searchOptions(news.translations[this.translationIndex].description)) && this.compareDate(news.translations[this.translationIndex].publishedAt))
					return true
			}) 
			return this.allNews
		},
		news() {
			return this.$store.state.news.filter(news => {
				let keep = true;
				news.translations.forEach(translation => {
					if (!translation.publishedAt) keep = false;
				})
				return keep;
			}).map(news => ({
				...news,
				translations: news.translations.map(translation => ({
					title: translation.title,
					thumb: translation.thumb ? `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${translation.thumb.id}` : ``,
					description: translation.description,
					slug: translation.slug,
					publishedAt: translation.publishedAt.slice(0,10),
				}))	
			}))
		},
		fullNews() {
			return this.$store.state.fullNews.filter(news => {
				let keep = true;
				news.translations.forEach(translation => {
					if (!translation.publishedAt) keep = false;
				})
				return keep;
			}).map(news => ({
				...news,
				translations: news.translations.map(translation => ({
					title: translation.title,
					thumb: translation.thumb ? `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${translation.thumb.id}` : ``,
					description: translation.description,
					slug: translation.slug,
					publishedAt: translation.publishedAt.slice(0,10),
				}))	
			}))
		},
	},
	mounted () {
		if (this.$route.query.search) {
			this.search = this.$route.query.search
		}
	},
	created() {
		this.$store.dispatch('fetchNews', { skip: 0, take: this.displayedNews })
		this.$store.dispatch('fetchFullNews')
	},
}
</script>

<style lang="stylus" scoped src="./Noticias.styl"></style>
